import React from 'react'
const Aboutus = () => {
    return (
        <div>
            <div>
                {/* Start Bottom Header */}
                <div className="page-area">
                    <div className="breadcumb-overlay" />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="breadcrumb text-center">
                                    <div className="section-headline white-headline text-center">
                                        <h3>About us</h3>
                                    </div>
                                    <ul>
                                        <li className="home-bread">Home</li>
                                        <li>About us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* END Header */}
                <div className="about-area area-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-6 col-xs-12">
                                <div class="section-headline text-center">
                                    <h3><span className="color-2">DEVELOPERS CODE</span></h3>
                                </div>
                                <div className="about-text">
                                    <p>Welcome to Developers Code, a leading software company committed to delivering innovative solutions that drive business growth and success. With a talented team of experts and a customer-centric approach, we empower organizations to leverage the power of technology and stay ahead in today's digital world.</p>
                                    <h4><span className="color-2">Who We Are</span></h4>
                                    <p>At Developers Code, we are a team of passionate professionals who are dedicated to transforming businesses through cutting-edge software solutions. With years of industry experience and expertise across various domains, we have helped numerous clients achieve their goals and overcome their technological challenges.</p>                                    
                                    <h4><span className="color-2">Our Mission</span></h4>
                                    <p>Our mission is to empower businesses by providing them with custom software solutions that are tailored to their unique needs. We strive to understand our clients' requirements, challenges, and objectives in order to deliver high-quality software that adds value and drives growth. We are committed to exceeding our clients' expectations and building long-lasting partnerships based on trust and mutual success.</p>
                                    <h4><span className="color-2">What We Offer</span></h4>
                                    <ul className="hidden-sm">
                                        <li><a href="#"><b>Custom Software Development:</b> We specialize in developing custom software solutions that are built from scratch to address the specific needs of our clients. Our team of experienced developers, designers, and quality analysts work together to create scalable, robust, and user-friendly applications that cater to diverse industries and sectors.</a></li>
                                        <li><a href="#"><b>Web and Mobile App Development:</b> We create dynamic web and mobile applications that engage users, enhance brand visibility, and provide seamless experiences across devices. Whether you need a responsive website, an intuitive mobile app, or a progressive web application, we have the expertise to bring your ideas to life.</a></li>
                                        <li><a href="#"><b>Product Development: </b> In addition to custom software development, we also have a strong focus on product development. If you have a unique software product idea, we can help you define the product roadmap, design the user experience, and develop the product with a strong emphasis on scalability, security, and market relevance.</a></li>
                                        <li><a href="#"><b>Software Consulting:</b> Our team of experts is available to provide software consulting services to help businesses make informed decisions about their technology strategies. We can assess your existing software infrastructure, identify areas for improvement, and provide recommendations on technology stack, architecture, and development methodologies.</a></li>
                                    </ul>
                                    <br></br>
                                    <h4><span className="color-2">Why Choose Us</span></h4>
                                    <ul className="hidden-sm">
                                        <li><a href="#"><b>Technical Excellence:</b> Our team consists of highly skilled professionals who are passionate about technology. We stay updated with the latest trends and advancements in the software industry, ensuring that we deliver solutions that are not only state-of-the-art but also future-proof.</a></li>
                                        <li><a href="#"><b>Customer-Centric Approach:</b> We prioritize our clients' needs and objectives. We work closely with them throughout the development process, ensuring that their requirements are met and their vision is realized. Our transparent and collaborative approach ensures that our clients are involved and informed every step of the way.</a></li>
                                        <li><a href="#"><b>Quality Assurance:</b> Quality is at the core of everything we do. Our dedicated quality assurance team conducts rigorous testing to ensure that the software we deliver is reliable, secure, and free of defects. We adhere to industry best practices and quality standards to provide our clients with superior solutions.</a></li>
                                        <li><a href="#"><b>Timely Delivery:</b> We understand the importance of meeting deadlines and milestones. We follow a well-defined project management process to ensure that our projects are delivered on time, within budget, and to the complete satisfaction of our clients.</a></li>
                                    </ul>
                                </div>
                                <br></br>
                                <h4>Join Us on the Journey</h4>
                                <p>Are you ready to take your business to new heights with customized software solutions? Partner with Developers Code and experience the power of technology-driven growth. Contact us today to discuss your project requirements, and let us help you achieve your business objectives through our innovative software solutions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Aboutus
