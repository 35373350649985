import React, { Component } from 'react'
import { Link } from 'react-router-dom';
export default class MainHeader extends Component {
    render() {
        return (
                <header className="header-two">
                    {/* Start top bar */}
                    <div className="topbar-area topbar-area-3 fix hidden-xs">
                        <div className="container">
                            <div className="row">
                                <div className=" col-md-9 col-sm-9">
                                    <div className="topbar-left">
                                        <ul>
                                            <li><Link to={{pathname: "https://web.whatsapp.com/send?phone=+918919248561&text=Hi"}} target="_blank"><i className="fa fa-whatsapp" />(+91)4035654526</Link></li>
                                            <li><Link to={{pathname: "mailto:info@developerscode.com"}} target="_blank"><i className="fa fa-envelope" /> info@developerscode.com</Link></li>
                                            <li><Link to={"/"}><i className="fa fa-clock-o" /> Mon - Fri: 10:00 - 18:00</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <div className="top-social">
                                        <ul>
                                            <li><Link to={{ pathname: "https://www.linkedin.com/company/developerscode" }} target="_blank"><i className="fa fa-linkedin" /></Link></li>
                                            <li><Link to={{pathname: "https://www.facebook.com/developercodes"}} target="_blank"><i className="fa fa-facebook" /></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="sticker" className="header-area header-area-2 header-area-3 hidden-xs">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3 col-sm-3">
                                    <div className="logo">
                                        <Link to={"/"} className="navbar-brand">
                                            <img src={"/img/logo/logo.png"}></img></Link>
                                    </div>
                                    {/* logo end */}
                                </div>
                                <div className="col-md-9 col-sm-9">
                                    {/* mainmenu start */}
                                    <nav className="navbar navbar-default">
                                        <div className="collapse navbar-collapse" id="navbar-example">
                                            <div className="main-menu">
                                                <ul className="nav navbar-nav navbar-right">
                                                <li><Link to={"/"} className="pagess">Home</Link></li>
                                                    <li><Link to={"/aboutus"} className="pagess">Company</Link>
                                                    <ul className="sub-menu">
                                                            <li><Link to={"/aboutus"}>About us</Link></li>
                                                            <li><Link to={"/careers"}>Careers</Link></li>
                                                            
                                                        </ul>
                                                    </li>
                                                    <li><Link className="pagess" to={"/services/artificial-intelligence"}>Services</Link>
                                                        <ul className="sub-menu">
                                                            <li><Link to={"/services/cloud-solutions"}>Cloud Solutions</Link></li>
                                                            <li><Link to={"/services/web-solutions"}>Web Solutions</Link></li>
                                                            <li><Link to={"/services/mobile-solutions"}>Mobile Solutions</Link></li>
                                                            <li><Link to={"/services/digital-marketing-services"}>Digital Marketing</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li><Link className="pagess" to={"/product/flight-booking-engine"}>Products</Link>
                                                        <ul className="sub-menu">
                                                            <li><Link to={"/product/travel-erp-software"}>Travel Booking Software</Link></li>
                                                            <li><Link to={"/product/realestate-erp-software"}>RealEstate CRM Solution</Link></li>
                                                            <li><Link to={"/product/school-erp-software"}>School ERP Solution</Link></li>
                                                        </ul>
                                                    </li>
                                                    {/* <li><Link className="pagess" to={"/product/flight-booking-engine"}>Travel Products</Link>
                                                        <ul className="sub-menu">
                                                            <li><Link to={"/product/flight-booking-engine"}>Travel CRM Software</Link></li>
                                                            <li><Link to={"/product/flight-booking-engine"}>B2C & B2B Solution</Link></li>
                                                            <li><Link to={"/product/flight-booking-engine"}>B2B Solution</Link></li>
                                                            <li><Link to={"/product/flight-booking-engine"}>Travel Agency Software</Link></li>
                                                            <li><Link to={"/product/flight-booking-engine"}>Flight Booking Software</Link></li>
                                                            <li><Link to={"/product/hotel-booking-engine"}>Hotel Booking Software</Link></li>
                                                            <li><Link to={"/product/hotel-booking-engine"}>Tour Package Software</Link></li>
                                                            <li><Link to={"/product/hotel-booking-engine"}>Bus Booking Software</Link></li>
                                                            <li><Link to={"/product/hotel-booking-engine"}>Car Booking Software</Link></li>
                                                        </ul>
                                                    </li> */}
                                                    <li><Link className="pagess" to={"/product/flight-booking-engine"}>API Solutions</Link>
                                                        <ul className="sub-menu">
                                                            <li><Link to={"/product/flight-booking-engine"}>Flight API Integration</Link></li>
                                                            <li><Link to={"/product/hotel-booking-engine"}>Hotel API Integration</Link></li>
                                                            <li><Link to={"/product/hotel-booking-engine"}>Car API Integration</Link></li>
                                                            <li><Link to={"/product/hotel-booking-engine"}>Bus API Integration</Link></li>
                                                            <li><Link to={"/product/flight-booking-engine"}>Amadeus GDS API Integration</Link></li>
                                                            <li><Link to={"/product/flight-booking-engine"}>Sabre GDS API Integration</Link></li>
                                                            <li><Link to={"/product/flight-booking-engine"}>Galileo GDS API Integration</Link></li>
                                                            <li><Link to={"/product/flight-booking-engine"}>TBO API Integration</Link></li>
                                                            <li><Link to={"/product/hotel-booking-engine"}>RezLive API Integration</Link></li>
                                                            <li><Link to={"/product/hotel-booking-engine"}>GRNConnect API Integration</Link></li>
                                                            <li><Link to={"/product/hotel-booking-engine"}>TripJack API Integration</Link></li>
                                                            <li><Link to={"/product/hotel-booking-engine"}>Payment Gateway API Integration</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li><Link to={"/hiredeveloper/dedicated-developer"} className="pagess">Hire Developers</Link>
                                                    <ul className="sub-menu">
                                                            <li><Link to={"/hire/dotnet-developers"}>Hire .Net Developers</Link></li>
                                                            <li><Link to={"/hire/angular-developers"}>Hire Angular Developers</Link></li>
                                                            <li><Link to={"/hire/react-developers"}>Hire React Developers</Link></li>
                                                            <li><Link to={"/hire/python-developers"}>Hire Python Developers</Link></li>
                                                            <li><Link to={"/hire/fullstack-developers"}>Hire FullStack Developers</Link></li>
                                                            <li><Link to={"/hire/mern-developers"}>Hire MERN Developers</Link></li>
                                                            <li><Link to={"/hire/mean-developers"}>Hire MEAN Developers</Link></li>
                                                            <li><Link to={"/hire/java-developers"}>Hire JAVA Developers</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li><Link to={"/contactus"}>Contact</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </nav>
                                    {/* mainmenu end */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* header-area end */}
                    
                </header>
        )
    }
}
