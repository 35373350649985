import React from 'react'
import SiderBar from '../Main/SideBar'
import OwlCarousel from 'react-owl-carousel2';
import "react-responsive-carousel/lib/styles/carousel.min.css";
export default function MobileSolutions() {
    const options = {
        'items': 4,
        'rewind': true,
        'autoplay': true,
        'loop': ((".owl-carousel .item").length > 1) ? true : false,
        'nav': false,
        'dots': false,
        'margin': 20,
        'responsive': {
            '768': {
                'items': 3
            },
            '992': {
                'items': 4
            }
        }
    }
    return (
        <div>
            {/* Start Bottom Header */}
            <div className="page-area" style={{ background: '/flightsuppliers/flightimage.jfif !important' }}>
                <div className="breadcumb-overlay" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="breadcrumb text-center">
                                <div className="section-headline white-headline text-center">
                                    <h3>Mobile Development Services</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="single-services-page area-padding">
                <div className="container">
                    <div className="row">
                        <SiderBar pagename={"Mobile"}></SiderBar>
                        {/* Start service page */}
                        <div className="col-md-8 col-sm-8 col-xs-12">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="single-well mar-well">
                                        <p>Welcome to Developers Code, your trusted partner for mobile development services. In today's mobile-first world, having a strong mobile presence is essential for businesses to thrive. Our team of skilled mobile app developers is dedicated to creating innovative and high-performance mobile applications that elevate your brand, engage your users, and drive business growth.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h4>Our Mobile Development Services</h4>
                                <div className="col-md-12">
                                    <div className="single-well mar-well">
                                        <ul className="marker-list">
                                            <li>Native Mobile App Development: Our experienced mobile app developers specialize in building native applications for iOS and Android platforms. We leverage the unique features and capabilities of each platform to deliver highly responsive, visually appealing, and user-friendly mobile apps that provide an exceptional user experience.</li>
                                            <li>Cross-Platform App Development: If you want to reach a wider audience while minimizing development costs, our cross-platform app development services are the perfect choice. Using frameworks like React Native and Flutter, we can develop apps that work seamlessly across multiple platforms, saving you time and resources without compromising on quality.</li>
                                            <li>Mobile UI/UX Design: We understand the importance of creating visually appealing and intuitive user interfaces (UI) and delivering exceptional user experiences (UX). Our team of UI/UX designers collaborates closely with our developers to ensure that your mobile app not only looks stunning but also provides a seamless and engaging experience for your users.</li>
                                            <li>Mobile App Backend Development: A robust and scalable backend is crucial for the success of your mobile app. Our mobile development services include backend development using reliable technologies like Node.js, Firebase, or Django. We ensure that your app's backend is secure, scalable, and capable of handling increased user loads as your business grows.</li>
                                            <li>App Integration and API Development: We can integrate your mobile app with third-party services, APIs (Application Programming Interfaces), or existing systems to enhance functionality and provide a seamless user experience. Whether it's integrating payment gateways, social media platforms, or external databases, our developers can handle the integration process smoothly.</li>
                                            <li>App Testing and Quality Assurance: Quality is our priority. We conduct rigorous testing throughout the development process to ensure that your mobile app is bug-free, performs optimally, and provides a flawless user experience. Our testing procedures include functional testing, usability testing, performance testing, and security testing to deliver a reliable and high-quality app.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <h4>Ready to Transform Your Mobile Presence?</h4>
                            <p>Enhance your business with a captivating mobile app that connects with your target audience and drives results. Contact us today to discuss your mobile development requirements, and let our team of skilled mobile app developers turn your vision into reality.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}