import React from 'react'
import SiderBar from '../Main/SideBar'
import OwlCarousel from 'react-owl-carousel2';
import "react-responsive-carousel/lib/styles/carousel.min.css";
export default function DigitalMarketing() {
    const options = {
        'items': 4,
        'rewind': true,
        'autoplay': true,
        'loop': ((".owl-carousel .item").length > 1) ? true : false,
        'nav': false,
        'dots': false,
        'margin': 20,
        'responsive': {
            '768': {
                'items': 3
            },
            '992': {
                'items': 4
            }
        }
    }
    return (
        <div>
            {/* Start Bottom Header */}
            <div className="page-area" style={{ background: '/flightsuppliers/flightimage.jfif !important' }}>
                <div className="breadcumb-overlay" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="breadcrumb text-center">
                                <div className="section-headline white-headline text-center">
                                    <h3>Digital Marketing Services</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="single-services-page area-padding">
                <div className="container">
                    <div className="row">
                        <SiderBar pagename={"Mobile"}></SiderBar>
                        {/* Start service page */}
                        <div className="col-md-8 col-sm-8 col-xs-12">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="single-well mar-well">
                                        <p>Welcome to Developers Code, your trusted partner for comprehensive digital marketing services. In today's competitive digital landscape, we understand the importance of establishing a strong online presence and reaching your target audience effectively. Our team of digital marketing experts is here to help you navigate the ever-evolving digital world and achieve your business goals.</p>
                                    </div>
                                </div>
                                </div>
                                <div className="row">
                                <h4>Our Services</h4>
                                <div className="col-md-12">
                                    <div className="single-well mar-well">
                                        <ul className="marker-list">
                                            <li>Search Engine Optimization (SEO): Our SEO services are designed to improve your website's visibility in search engine results, driving organic traffic and increasing your online presence. We employ proven strategies and techniques to optimize your website, enhance its search engine rankings, and attract quality leads.</li>
                                            <li>Pay-Per-Click (PPC) Advertising: With our PPC advertising services, we can create targeted campaigns that drive immediate results. Our experts specialize in crafting compelling ad copy, optimizing keywords, and managing your PPC budget to maximize your return on investment (ROI).</li>
                                            <li>Social Media Marketing: Leverage the power of social media to engage with your audience and build brand awareness. Our social media marketing services encompass strategic planning, content creation, community management, and targeted advertising across various platforms to drive engagement and conversions.</li>
                                            <li>Content Marketing: Engaging and relevant content is the backbone of successful digital marketing. We can develop a content strategy tailored to your target audience, creating high-quality content that drives traffic, establishes thought leadership, and nurtures customer relationships.</li>
                                            <li>Email Marketing: Build and maintain relationships with your customers through effective email marketing campaigns. Our team can help you create personalized email campaigns, segment your audience, and measure campaign performance to drive customer engagement and increase conversions.</li>
                                            <li>Conversion Rate Optimization (CRO): Enhance your website's performance and maximize conversions with our CRO services. We analyze user behavior, conduct A/B testing, and optimize your website's design and user experience to increase conversion rates and drive revenue growth.</li>
                                            <li>Analytics and Reporting: Gain valuable insights into your digital marketing efforts with our comprehensive analytics and reporting services. We provide detailed reports on key performance indicators, campaign effectiveness, and user behavior to help you make data-driven decisions and refine your marketing strategies.</li>                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h4>Why Choose Us</h4>
                                <div className="col-md-12">
                                    <div className="single-well mar-well">
                                        <ul className="marker-list">
                                            <li>Expertise and Experience: Our team consists of seasoned digital marketing professionals who have a deep understanding of the industry and its trends. With years of experience, we have successfully helped numerous clients achieve their digital marketing objectives.</li>
                                            <li>Tailored Strategies: We believe that every business is unique, and a one-size-fits-all approach doesn't work. We take the time to understand your business, industry, and target audience to create customized strategies that align with your goals and deliver results.</li>
                                            <li>Results-Driven Approach: We are committed to delivering measurable results and driving tangible outcomes for your business. Our strategies are focused on generating leads, increasing conversions, and maximizing your ROI.</li>
                                            <li>Transparent Communication: We believe in building trust and fostering open communication with our clients. Throughout the engagement, we provide regular updates, reports, and insights, ensuring that you stay informed about the progress and success of your digital marketing campaigns.</li>
                                            <li>Collaboration and Partnership: We view ourselves as an extension of your team and strive to develop a strong partnership with our clients. By working closely with you, we aim to understand your business objectives, challenges, and opportunities, allowing us to deliver tailored solutions that drive your success.</li>                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <h4>Ready to Boost Your Digital Presence?</h4>
                            <p>Take your digital marketing efforts to the next level with our comprehensive and results-driven services. Contact us today to discuss your requirements and let our team of digital marketing experts craft a customized strategy that will drive your business forward in the digital realm.</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>       
    )
}