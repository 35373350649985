import React from 'react'
import SiderBar from '../Main/SideBar'
import OwlCarousel from 'react-owl-carousel2';
import "react-responsive-carousel/lib/styles/carousel.min.css";
export default function WebSolutions() {
    const options = {
        'items': 4,
        'rewind': true,
        'autoplay': true,
        'loop': ((".owl-carousel .item").length > 1) ? true : false,
        'nav': false,
        'dots': false,
        'margin': 20,
        'responsive': {
            '768': {
                'items': 3
            },
            '992': {
                'items': 4
            }
        }
    }
    return (
        <div>
            {/* Start Bottom Header */}
            <div className="page-area" style={{ background: '/flightsuppliers/flightimage.jfif !important' }}>
                <div className="breadcumb-overlay" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="breadcrumb text-center">
                                <div className="section-headline white-headline text-center">
                                    <h3>Web Development Services</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="single-services-page area-padding">
                <div className="container">
                    <div className="row">
                        <SiderBar pagename={"Web"}></SiderBar>
                        {/* Start service page */}
                        <div className="col-md-8 col-sm-8 col-xs-12">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="single-well mar-well">
                                        <p>Welcome to Developers Code, your one-stop destination for professional web development services. In today's digital age, a strong online presence is essential for businesses to succeed. Our team of experienced web developers is dedicated to delivering high-quality, customized web solutions that help you stand out from the competition and achieve your business objectives.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h4>Our Web Development Services</h4>
                                <div className="col-md-12">
                                    <div className="single-well mar-well">
                                        <ul className="marker-list">
                                            <li>Custom Web Development: We specialize in building custom websites tailored to your unique business requirements. Our team of skilled developers combines cutting-edge technologies with industry best practices to create intuitive, user-friendly, and visually stunning websites that leave a lasting impression on your visitors.</li>
                                            <li>Responsive Web Design: With the growing number of mobile users, having a responsive website is crucial. Our web development services include responsive design, ensuring that your website adapts seamlessly to different screen sizes and devices. This provides an optimal user experience and maximizes your reach across desktops, tablets, and smartphones.</li>
                                            <li>E-Commerce Development: Ready to take your business online and tap into the vast potential of e-commerce? Our web development team specializes in creating robust and secure e-commerce websites. We can integrate popular e-commerce platforms like Shopify, Magento, or develop a custom e-commerce solution tailored to your specific needs.</li>
                                            <li>Content Management Systems (CMS): We empower you to manage and update your website effortlessly with our CMS solutions. Whether it's WordPress, Drupal, or a custom-built CMS, our developers can create a user-friendly content management system that enables you to add, edit, and delete content, ensuring that your website remains fresh and up-to-date.</li>
                                            <li>Web Application Development: Need a powerful web application to streamline your business processes or engage your users? Our skilled web developers can bring your ideas to life by building secure, scalable, and feature-rich web applications. From customer portals to online booking systems, we can develop custom web applications tailored to your specific requirements.</li>
                                            <li>API Development and Integration: We can develop and integrate APIs (Application Programming Interfaces) to connect your website or web application with third-party services, platforms, or databases. This allows you to extend the functionality of your website, integrate with external systems, and automate processes, enhancing the overall user experience.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h4>Our Technology Services</h4>
                                <div className="col-md-6">
                                    <div className="single-well mar-well">
                                        <ul className="marker-list">
                                            <li>ASP.NET Web Development</li>
                                            <li>FullStack MEAN Development Services</li>
                                            <li>Magento Web Development</li>
                                            <li>FullStack MERN Development Services</li>
                                            <li>Laravel Web Development</li>
                                            <li>PHP Web Development</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-well mar-well">
                                        <ul className="marker-list">
                                            <li>Wordpress Web Development</li>
                                            <li>Python Web Development</li>
                                            <li>React.JS Development Services</li>
                                            <li>Angular Development Services</li>
                                            <li>Node.JS Development Services</li>
                                            <li>Shopify Web Development</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}