import OwlCarousel from 'react-owl-carousel2';
export default function Clients(props) {
    const options = {
        'items': 3,
        'rewind': true,
        'autoplay': true,
        'loop': ((".owl-carousel .item").length > 1) ? true : false,
        'nav': false,
        'dots': false,
        'margin': 20,
        'responsive': {
            '768': {
                'items': 3
            },
            '992': {
                'items': 3
            }
        }
    }
    return (
        <div className="project-area project-area-2 area-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 ">
                        <div className="section-headline text-center">
                            <h3>Client Testimonials</h3>
                            <p>Hear What Our Customers Have to Say!</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="project-carousel">
                    <OwlCarousel options={options} margin={0}>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="single-awesome-project">
                                <div className="awesome-img">
                                        <img src="/clients/avatar.png" alt />
                                </div>
                                <div className="project-dec">
                                    <h4>Kuusoft Software Corp, Canada</h4>
                                    <p>Tanisha is very skilled and knowledgeable. He is also very dedicated and ensures we get all the hours we needed for our team. Tanisha completed every task that's assigned accurately. If you are looking for a developer for your application, he is definitely a strong candidate. Will hire him back if there's a project in similar scope in the future.Great job Tanisha!"</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            {/* single-awesome-project start */}
                            <div className="single-awesome-project">
                                <div className="awesome-img">
                                    <img src="/clients/avatar.png" alt />
                                </div>
                                <div className="project-dec">
                                    <h4>Freedom Electronics LLC, United States</h4>
                                    <p>Tanisha and Developers code team delivered good work on this Web UI development project . His communication was top-notch, he met all deadlines, and his skills were reasonably strong. I really liked their work and would strongly recommend to others</p>
                                </div>
                            </div>
                            {/* single-awesome-project end */}
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            {/* single-awesome-project start */}
                            <div className="single-awesome-project">
                                <div className="awesome-img">
                                    <img src="/clients/avatar.png" alt />
                                </div>
                                <div className="project-dec">
                                    <h4>Client, United States</h4>
                                    <p>I want to thank Tanisha and his team for the work they did for us. Tanisha was a great communicator and was willing to deliver exactly what we asked for. I would definitely recommend his services and we hope to work with him and his team in future projects.</p>
                                </div>
                            </div>
                            {/* single-awesome-project end */}
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            {/* single-awesome-project start */}
                            <div className="single-awesome-project">
                                <div className="awesome-img">
                                    <img src="/clients/avatar.png" alt />
                                </div>
                                <div className="project-dec">
                                    <h4>Digital Info Bytes, India</h4>
                                    <p>Tanisha is a very good freelancer to work with. He accommodated a lot of my requests without any questions ( which I compensated with a good bonus ). He never lacked in the response time to my messages or delivery time to deliver the change requests. You will love to work with him without worrying about him getting concerned about the changes that are inevitable in a project lifecycle.</p>
                                </div>
                            </div>
                            {/* single-awesome-project end */}
                        </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
            {/* End main content */}
        </div>

    )
}