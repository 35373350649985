import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import Clients from '../Company/Clients';
export default function SubContent() {
  const options = {
    'items': 2,
    'rewind': true,
    'autoplay': true,
    'loop': ((".owl-carousel .item").length > 1) ? true : false,
    'nav': false,
    'dots': false,
    'margin': 20,
    'responsive': {
      '768': {
        'items': 3
      },
      '992': {
        'items': 4
      }
    }
  }
  return (
    <div>
      {/* Start Slider Area */}
      <div className="intro-area intro-area-3">
        <div className="bg-wrapper">
          <img src="img/background/bg2.jpg" />
        </div>
        <div className="intro-content">
          <div className="slider-content">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="slide-all-text">
                    {/* layer 1 */}
                    <div className="layer-1 wow fadeInUp" data-wow-delay="0.3s">
                      <h2 className="title2">Best <span className="color-2">Travel</span> Technology Company</h2>
                    </div>
                    {/* layer 2 */}
                    <div className="layer-2 wow fadeInUp" data-wow-delay="0.5s">
                      <p>We are leading travel portal development company, offering B2B, B2C & corporate travel portals with Various GDS XML and third party APIs integration</p>
                    </div>
                    {/* layer 3 */}
                    <div className="layer-3 wow fadeInUp" data-wow-delay="0.7s">
                      <Link to={{ pathname: "mailto:info@developerscode.com" }} target="_blank" className="ready-btn">Get In Touch</Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 hidden-xs">
                  <div className="slide-images-inner wow fadeInUp" data-wow-delay="0.5s">
                    <div className="slide-images">
                      <img src="img/slider/s1.png" alt />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Slider Area */}
           
      <div className="welcome-area welcome-area-2 bg-color area-padding-2">
        <div className="container">
          <div class="section-headline text-center" style={{"paddingBottom":0}}>
            <h3><span className="color-2">DEVELOPERS CODE</span></h3>
          </div>
          <p style={{"paddingBottom":30}}>Welcome to Developers Code, a leading software company committed to delivering innovative solutions that drive business growth and success. With a talented team of experts and a customer-centric approach, we empower organizations to leverage the power of technology and stay ahead in today's digital world.At Developers Code, we are a team of passionate professionals who are dedicated to transforming businesses through cutting-edge software solutions. With years of industry experience and expertise across various domains, we have helped numerous clients achieve their goals and overcome their technological challenges.</p>
          <div className="row">
            <div className="all-well-services">
              {/* single-well end*/}
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="well-services">
                  <div className="well-img">
                    <Link className="big-icon" to={"/product/travel-erp-software"}><i className="fa fa-globe" /></Link>
                  </div>
                  <div className="main-wel">
                    <div className="wel-content">
                      <h4>Travel Management Software</h4>
                      <p>Our Travel ERP Software is designed to empower travel agencies, tour operators, and travel management companies with advanced tools and features to streamline their operations, increase productivity, and boost revenue. </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="well-services">
                  <div className="well-img">
                    <Link className="big-icon" to={"/product/realestate-erp-software"}><i className="fa fa-building" /></Link>
                  </div>
                  <div className="main-wel">
                    <div className="wel-content">
                      <h4>RealEstate ERP Software</h4>
                      <p>Are you in search of a robust and efficient software solution to manage your real estate business? Look no further! Our Real Estate ERP Software is designed to empower real estate developers, agents, and property management companies with advanced tools </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="well-services">
                  <div className="well-img">
                    <Link className="big-icon" to={"/product/school-erp-software"}><i className="fa fa-building" /></Link>
                  </div>
                  <div className="main-wel">
                    <div className="wel-content">
                      <h4>School ERP Software</h4>
                      <p>Our School ERP Software is designed to empower schools, colleges, and universities with advanced tools and features to enhance administrative efficiency, improve communication, and elevate the learning experience.</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* single-well end*/}
            </div>
          </div>
        </div>
      </div>
      <div className="about-area area-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-6 col-xs-12">
              <div className="about-text">
                
                <h4><span className="color-2">WHAT WE OFFER</span></h4>
                <ul className="hidden-sm">
                  <li><a href="#"><b>Custom Software Development:</b> We specialize in developing custom software solutions that are built from scratch to address the specific needs of our clients. Our team of experienced developers, designers, and quality analysts work together to create scalable, robust, and user-friendly applications that cater to diverse industries and sectors.</a></li>
                  <li><a href="#"><b>Web and Mobile App Development:</b> We create dynamic web and mobile applications that engage users, enhance brand visibility, and provide seamless experiences across devices. Whether you need a responsive website, an intuitive mobile app, or a progressive web application, we have the expertise to bring your ideas to life.</a></li>
                  <li><a href="#"><b>Product Development: </b> In addition to custom software development, we also have a strong focus on product development. If you have a unique software product idea, we can help you define the product roadmap, design the user experience, and develop the product with a strong emphasis on scalability, security, and market relevance.</a></li>
                  <li><a href="#"><b>Software Consulting:</b> Our team of experts is available to provide software consulting services to help businesses make informed decisions about their technology strategies. We can assess your existing software infrastructure, identify areas for improvement, and provide recommendations on technology stack, architecture, and development methodologies.</a></li>
                </ul>
              </div>
              <br></br>
              <h4>Join Us on the Journey</h4>
              <p>Are you ready to take your business to new heights with customized software solutions? Partner with Developers Code and experience the power of technology-driven growth. Contact us today to discuss your project requirements, and let us help you achieve your business objectives through our innovative software solutions.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="achivement-area achivement-area-3 area-padding-2">
        <div className="container">
          {/* Start counter Area */}
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 white-headline">
              <div className="section-headline white-headline text-center">
                <h3>Travel Software Development</h3>                
              </div>
              <p>Are you looking for a comprehensive solution to manage your travel business more efficiently? Look no further! Our Travel ERP Software is designed to empower travel agencies, tour operators, and travel management companies with advanced tools and features to streamline their operations, increase productivity, and boost revenue.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              {/* single-well end*/}
              <div className="digital-services">
                <div className="digital-wel s1-item">
                  <div className="digital-img">
                    <Link className="digital-icon" to={"/"}><i class="fa fa-plane"></i></Link>
                  </div>
                  <div className="digital-content">
                    <h4>Flight Booking Software</h4>
                    <p>Flight booking software which automates flight booking process to help book flight online for particular seats available from various flights and increase revenue...</p>
                  </div>
                </div>
              </div>
            </div>
            {/* single-well end*/}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="digital-services">
                <div className="digital-wel s2-item">
                  <div className="digital-img">
                    <Link className="digital-icon" href="#"><i class="fa fa-building"></i></Link>
                  </div>
                  <div className="digital-content">
                    <h4>Hotel Booking Software</h4>
                    <p>Hotel reservation system comes with B2C and B2B modules and process all hotel reservations made via hotel website to maximize hotel revenue...</p>
                  </div>
                </div>
              </div>
            </div>
            {/* single-well end*/}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="digital-services">
                <div className="digital-wel s4-item">
                  <div className="digital-img">
                    <Link className="digital-icon" href="#"><i class="fa fa-suitcase"></i></Link>
                  </div>
                  <div className="digital-content">
                    <h4>Tour Package System</h4>
                    <p>It helps Inbound and Outbound Tour operators to automate sales, operations, and finances to manage online bookings, itinerary creation, quotation management...</p>
                  </div>
                </div>
              </div>
            </div>
            {/* single-well end*/}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="digital-services">
                <div className="digital-wel s5-item">
                  <div className="digital-img">
                    <Link className="digital-icon" href="#"><i class="fa fa-book"></i></Link>
                  </div>
                  <div className="digital-content">
                    <h4>Visa Booking Software</h4>
                    <p>Online visa booking system helps customers to apply visa online for both normal and express visas.End users can book and cancel visas as and when they please.</p>
                  </div>
                </div>
              </div>
              {/* single-well end*/}
            </div>
          </div>
        </div>
      </div>
      <Clients></Clients>
    </div>
  )
};